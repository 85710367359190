var cc = initCookieConsent();

cc.run({
    current_lang: document.documentElement.getAttribute('lang'),
    revision: 1,
    page_scripts: true,
    autoclear_cookies: true,
    languages: {
        'cs': {
            consent_modal: {
                title: 'Využíváme cookies',
                description: 'Tyto webové stránky používají k poskytování svých služeb soubory Cookies. Používáním těchto webových stránek souhlasíte s použitím souborů Cookies. <button type="button" data-cc="c-settings" class="cc-link">Nastavení cookies</button>',
                primary_btn: {
                    text: 'Přijmout vše',
                    role: 'accept_all'
                },
                secondary_btn: {
                    text: 'Odmítnout vše',
                    role: 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Nastavení cookies',
                save_settings_btn: 'Uložit nastavení',
                accept_all_btn: 'Přijmout vše',
                reject_all_btn: 'Odmítnout vše',
                close_btn_label: 'Zavřít',
                cookie_table_headers: [
                    {col1: 'Jméno'},
                    {col2: 'Doména'},
                    {col3: 'Expirace'},
                    {col4: 'Popis'}
                ],
                blocks: [
                    {
                        title: 'Co jsou to cookies?',
                        description: 'Soubory cookies a další technologie nám pomáhají zlepšovat naše služby a pomáhají nám analyzovat výkon webu. V nastavení si můžete vybrat, které cookies můžeme používat. Svůj souhlas můžete kdykoliv odvolat.'
                    }, {
                        title: 'Nezbytné cookies pro správné fungování našeho webu.',
                        description: 'Tyto cookies jsou nezbytně nutné pro správnou funkci webu - zajišťují správné zobrazení, odesílání formulářů, vkládání zboží do košíku a podobně. Technické cookies není možné vypnout.',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true
                        },
                        cookie_table: [
                            {
                                col1: 'nette-samesite',
                                col2: 'obcekyjovska.cz',
                                col3: '1 sezení',
                                col4: 'Nette prevence CSFR útoku'
                            }
                        ]
                    }, {
                        title: 'Sledování návštěvnosti a chování na webu nám pomáhá zlepšovat naše služby.',
                        description: 'Statistické cookies používáme k vyhodnocování výkonu našeho webu a reklamních kampaní. Pomocí cookies určujeme počet návštěv, zdroje návštěvnosti a chování zákazníků na našem webu - např. informace o tom, co na webu hledáte a které informace jsou pro vás nejdůležitější. Na základě těchto dat můžeme provádět optimalizaci webu a vylepšovat naše služby.',
                        toggle: {
                            value: 'analytics',
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [
                            {
                                col1: '^_ga',
                                col2: 'obcekyjovska.cz',
                                col3: '2 roky',
                                col4: 'Google Analytics',
                                is_regex: true
                            },
                            {
                                col1: '_gid',
                                col2: 'obcekyjovska.cz',
                                col3: '1 den',
                                col4: 'Google Analytics',
                            }
                        ]
                    }
                ]
            }
        }
    }
});
